.slid{
  width: 100%;
  height: 100rem;
  overflow: hidden !important;

  &__slider {
    width: auto;
    overflow:inherit;
    display: flex;
    flex-direction: row;
    height: 30vh;
    background: url("../../../assets/img/background_films.png") no-repeat left;
    background-size: cover;
    margin-top: 5vh;
    justify-content: space-around;
    vertical-align: center;
    text-align: center;
    font-size: 2.5vh;
    color: #452a15;

    &-films {
      position: relative;
      width: 26vh;
      height: 18vh;
      margin-top: 6vh;
      padding-right: 2vh;
      justify-content: space-around;
      z-index: 110;
      &-first {
        padding-right: 2vh;
        width: 8.5vh;
        height: 18vh;
        margin-top: 6vh;
      }
      &-end{
        width: 7vh;
        height: 18vh;
        margin-top: 6vh;
      }
    }
    &_btn {
      font-family: Hangyaboly !important;
      background-color: white;
      margin-top: 8vh;
      border: none;
    }

    &_render {
      position: relative;
      top: 0;
      left: 0;
      width: 26vh;
      height: 16vh;

      &-photo {
        position: relative;
        top: 0;
        left: 0;
        width: 24vh;
        height: 15.5vh;
        margin-top: 7vh;
        padding-right: 2vh;
        overflow: hidden;
      }

      &-border {
        position: absolute;
        width: 26vh;
        height: 18vh;
        margin-top: 6vh;
        margin-left: -25vh;
        padding-right: 2vh;
      }
    }
  }
}

@media screen
and (max-width: 1024px)
and (orientation : portrait){
  .slid {
    &__slider {
      display: none;
    }
  }
}
@media screen
and (max-width: 567px)
and (orientation : landscape) {

  .slid {
    &__slider {
      display: none;
    }
  }
}