.carousel {
  &__home {
    position: relative;
    background: url("../../../assets/img/background_films.png") no-repeat left;
    background-size: cover;
    margin-top: -18rem;
    height: 44vh;
    text-align: center;
    &_slide {
      position: relative;
      margin-left: 1vh;
      margin-top: 8vh;
      height: 35rem;
      &_films {
        top: 0;
        left: 0;
        position: relative;
        width: 35vh;
        height: 25vh;
        padding-top: 1vh;
      }
      &_border {
        position: absolute;
        width: 37vh;
        height: 27vh;
        margin-left: -36vh;
      }
      &_control_left {
      }
      &_control_right {
      }
      &_btn {
        font-family: Hangyaboly !important;
        position: absolute;
        background-color: white;
        margin-top: 37vh;
        margin-left: -23vh;
        z-index: 1080;
      }
    }
  }
}

@media only screen
and (min-device-width : 354px)
and (orientation : landscape) {
  .carousel {
    &__home {
      display: none;
    }
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  .carousel {
    &__home {
      &_slide {
        margin-top: 8vh;
        height: 35rem;
      }
    }
  }
}

@media only screen
and (min-device-width : 769px)
and (orientation : portrait) {
  .carousel {
    &__home {
      &_slide {
        margin-top: 10rem;
        height: 28rem;
      }
    }
  }
}