.work_menu {
  margin: 5vh auto;
  padding: 2vh;

  &_card {
    border: none;
    text-align: center;
    font-family: Calibri, regular;
    font-size: 1.5vh;
    color: #633a21;
    opacity: 1;
    &-off {
      display: none;
    }
  }
}

@media only screen
and (max-device-width : 1024px)
and (orientation : portrait) {
  .work_menu {
    &_card {
      display: none;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : landscape) {
  .work_menu {
    &_card {
      font-size: 0.8rem;
    }
  }
}

@media only screen
and (min-device-width : 411px)
and (max-device-width : 823px)
and (orientation : landscape) {
  .work_menu {
    &_card {
      font-size: 0.8rem;
    }
  }
}

@media only screen
and (min-device-width : 411px)
and (max-device-width : 736px)
and (orientation : landscape) {
  .work_menu {
    &_card {
      width: 2.8rem;
      font-size: 0.5rem;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
  .work_menu {
    &_card {
      width: 3rem;
      padding: 0rem;
      font-size: 0.6rem;
    }
  }
}