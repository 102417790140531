.work_content{
  &__card {
    margin: 1vh;
    width: 32rem;
    background-color: #f7a83d;
    border: 1.5px solid #633b22;
    border-radius: 1vh;
    font-family: Calibri, bold;
    font-size: 2vh;
    &-info {
      height: auto;
      &-header {
        font-size: 2vh;
      }
      &-header-title {
        font-size: 3vh;
      }
      &-footer-price {
        font-size: 3vh;
      }
    }
  }
}
@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {
  .work_content {
    margin: -3rem 0 0 0;
    &__card {
      font-size: 1.2rem;

      &-info {
        height: auto;
        &-header {
          font-size: 2vh;
        }
        &-header-title {
          font-size: 3vh;
        }
        &-footer-price {
          font-size: 3vh;
        }
      }
    }
  }
}
@media only screen
and (max-device-width : 1024px)
and (orientation : portrait) {
  .work_content {
    &__card {
      font-size: 1.2rem;
      &-info {
        height: auto;
        &-header {
          font-size: 2vh;
        }
        &-header-title {
          font-size: 3vh;
        }
        &-footer-price {
          font-size: 3vh;
        }
      }
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : landscape) {
  .work_content {
    &__card {
      margin-top: 1.5rem;
      &-info {
        &-header {
          font-size: 1rem;
        }
        &-header-title {
          font-size: 1.5rem;
        }
        &-footer-price {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media only screen
and (min-device-width : 411px)
and (max-device-width : 823px)
and (orientation : landscape) {
  .work_content {
    &__card {
      font-size: 1.2rem;
      margin-top: 2rem;
      &-info {
        &-header {
          font-size: 1rem;
        }
        &-header-title {
          font-size: 1.5rem;
        }
        &-footer-price {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media only screen
and (min-device-width : 411px)
and (max-device-width : 737px)
and (orientation : landscape) {
  .work_content {
    margin: -3rem 0 0 0;
    &__card {
      font-size: 1.2rem;

      &-info {
        &-header {
          font-size: 1rem;
        }
        &-header-title {
          font-size: 1.5rem;
        }
        &-footer-price {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
  .work_content {
    &__card {
      &-info {
        &-header {
          font-size: 1rem;
        }
        &-header-title {
          font-size: 1.5rem;
        }
        &-footer-price {
          font-size: 1.5rem;
        }
      }
    }
  }
}

