.svg {
  &_vertical_line {
    &__header {
      overflow-y: hidden;
      margin-top: -0.5rem;
      padding: 0;
      width: 3px;
      height: 5rem;
      text-align: center;
      color: #633b22
    }
  }
  &_horisontal_line {
    &__home{
      width: 10vh;
      height: 0.2vh;
      overflow-x: hidden;
      padding: 0;
      text-align: center;
      color: #633b22
    }
  }
  &_horisontal_line {
    &__work {
      width: 6vh;
      height: 0.2vh;
      overflow-x: hidden;
      padding: 0;
      text-align: center;
      color: #633b22
    }
  }
}

@media screen and (max-width: 1200px) {
  .svg {
    &_vertical_line {
      &__header {
        margin-top: 0rem;
        height: 5rem;
        width: 3px;
      }
    }
  }
}

@media screen and (max-width: 810px) {
  .svg {
    &_vertical_line {
      &__header {
        margin-top: 0rem;
        height: 45px;
        width: 2px;
      }
    }
  }
}

@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : landscape) {
  .svg {
    &_vertical_line {
      &__header {
        height: 35px;
      }
    }
    &_horisontal_line {
      &__home{
        width: 40px !important;
        height: 2px;
      }
    }
  }
}

@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : portrait) {
  .svg {
    &_vertical_line {
      &__header {
        height: 45px;
        width: 5px;
      }
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3){
  .svg {
    &_vertical_line {
      &__header {
        height: 45px;
      }
    }
  }
}


@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
  .svg {
    &_vertical_line {
      &__header {
        height: 35px;
      }
    }
    &_horisontal_line {
      &__home{
        width: 40px !important;
        height: 2px;
      }
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {
  .svg {
    &_vertical_line {
      &__header {
        height: 35px;
        width: 1px;
      }
    }
  }
}
@media only screen
and (min-device-width : 360px)
and (max-device-width : 640px)
and (orientation : portrait) {
  .svg {
    &_vertical_line {
      &__header {
        height: 35px;
        width: 1px;
      }
    }
  }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {
  .svg {
    &_vertical_line {
      &__header {
        height: 30px;
        width: 1px;
      }
    }
  }
}
