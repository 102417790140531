body {
  overflow-x: hidden;
}

h6 {
  font-size: 1.4vmin;
  margin: 0 auto;
  padding: -2px;
}

a {
  color: #f1dfc2;
}

a:hover {
  color: #f1dfc2;
  opacity: 0.8;
}

p {
  margin: 0 auto;
  padding: 0vh;
}


.btn_call_to {
  text-align: center;
  border-color: white;
}

.home {
  &_info_payment {
    font-family: Hangyaboly, regular;
    font-size: 4vmin;
    color: #595f2c;
    text-align: center;
  }

  &_menu {
    margin: 5vh auto;
    &_card {
      position: relative;
      border: none;
      text-align: center;
      font-family: Calibri, regular;
      font-size: 2rem;
      color: #633a21;
      padding: 0;
    }
  }

  &_we_worked {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 2rem;
    text-align: center;
    font-size: 1.8rem;
    font-family: Hangyaboly, regular !important;
  }
}

.portfolio {
  margin: 0 auto;
  text-align: center;
  font-family: Hangyaboly, regular !important;
  &_main {
    margin-top: 4rem;
    height: auto;
  }
  &_button {
    margin: 1rem;
  }
  &_pre {
    width: 100%;
    padding: 0 10% 0 10%;
    margin: 6rem 0 10rem 0;
    font-family: Hangyaboly, regular !important;
    &_btn_not-active {
      width: 20rem;
      pointer-events: none;
      cursor: default;
      text-decoration: none;
      opacity: 0.5;
    }
  }
  &_cards {
    width: auto !important;
    height: 25rem !important;
    margin-left: 1rem;
    border: 0.25rem solid #633a21;
    border-radius: 0.75rem;
    background: #f8f9fa;
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .home {
    &_info_payment {
      margin-left: 2rem;
      font-size: 1.8rem;
      &_img {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 810px) {
  .home {
    &_info_payment {
      margin-left: 1rem;
      font-size: 2rem;
      &_img {
        display: none;
      }
    }
  }
  .portfolio {
    &_button {
      margin: 2rem auto;
    }
    &_cards {
      border: 1.5px solid;
      border-radius: 0.5rem;
      height: 15rem !important;
    }
  }
}


@media screen
and (max-width: 1366px)
and (orientation : landscape){
  .home {
    &_we_worked {
      margin-top: 5rem;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : landscape) {
  .home {
    &_info_payment {
      margin-top: 5rem;
      font-size: 1.4rem !important;
    }
    &_menu {
      &_card {
        margin-left: 6rem;
        width: 55% !important;
        font-size: 1.2rem;
      }
    }
    &_we_worked {
      font-size: 1.4rem;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : portrait) {
  .home {
    &_info_payment {
      margin-top: 5rem;
      font-size: 1.4rem !important;
    }
    &_menu {
      &_card {
        //width: 6rem;
        font-size: 1.4rem;
      }
    }
    &_we_worked {
      font-size: 1.4rem;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
  .home {
    &_info_payment {
      padding-bottom: 2rem;
      font-size: 1.5rem !important;
    }
    &_menu {
      margin-left: 1rem;
      &_card {
        width: 12rem;
      }
    }
    &_we_worked {
      font-size: 1.4rem;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {
  .home {
    &_info_payment {
      margin-top: 3rem;
      margin-left: 1rem;
      font-size: 1.5rem;
      &_img {
        display: none;
      }
    }
  }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {
  .home {
    &_info_payment {
      margin-top: 2rem;
      font-size: 1.2rem !important;
    }
    &_menu {
      &_card {
        font-size: 1.4rem;
      }
    }
    &_we_worked {
      font-size: 1.4rem;
    }
  }
}