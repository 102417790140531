.footer {
  margin-top: 29vh;
  height: 27rem;
  background-color: #452a15;
  color: white;
  &_logo {
    width: 8rem;
    margin-top: -8rem;
  }
  &_altlogo {
    display: none;
  }
  &_left-icon {
    width: 33rem;
    margin-top: -25rem;
  }
  &_right-icon {
    width: 27rem;
    margin-top: -30rem;
  }
  &_text {
    padding: 0.5rem;
    text-align: center;
    font-size: 3vmin;
    font-family: Calibri, bold !important;
    &-production {
      font-family: Hangyaboly, regular;
      margin: 3rem auto;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1700px) {
  .footer {
    margin-top: 20rem;
    &_right-icon {
      margin-top: -27rem;
      padding-right: 1rem;
      width:25rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer {
    height: 30rem;
    margin-top: 15rem;
    &_logo {
      display: none;
    }
    &_altlogo {
      display: contents;
    }
    &_left-icon {
      position: relative;
      margin-top: -22rem;
      padding-right: 1rem;
      width: 30rem;
    }
    &_right-icon {
      margin-top: -20rem;
      padding-right: 1rem;
      width: 20rem;
    }
  }
}

@media only screen
and (min-device-width : 767px)
and (max-device-width : 1025px)
and (orientation : portrait) {
  .footer {
    height: 40rem !important;
    &_left-icon {
      margin-top: -15rem !important;
      width: 20rem !important;
    }
    &_right-icon {
      display: initial !important;
      margin-top: -15rem !important;
      width: 14rem !important;
    }
  }
}

@media screen and (max-width: 810px) {
  .footer {
    height: 30rem;
    margin-top: 15rem !important;
    &_left-icon {
      margin-top: -22rem;
      padding-left: 1rem;
      width: 30rem;
    }
    &_right-icon {
      display: none;
    }
    &_text {
      margin-top: 2rem;
      font-size: 2rem;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : landscape) {
  .footer {
    margin-top: 10rem;
    &_left-icon {
      margin-top: -12rem !important;
      width: 16rem !important;
    }
    &_right-icon {
      display: initial !important;
      margin-top: -12rem !important;
      width: 11rem !important;
    }
    &_text {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
  }
}

@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : portrait) {
  .footer {
    margin-top: 15rem;
    &_left-icon {
      margin-top: -16rem !important;
      width: 30rem !important;
    }
    &_right-icon {
      display: none;
    }
    &_text {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
  .footer {
    margin-top: 10rem !important;
    &_left-icon {
      margin-top: -12rem !important;
      width: 16rem !important;
    }
    &_right-icon {
      display: initial !important;
      margin-top: -12rem !important;
      width: 11rem !important;
    }
    &_text {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {
  .footer {
    margin-top: 15rem;
    &_left-icon {
      margin-top: -16rem !important;
      width: 22rem !important;
    }
    &_right-icon {
      display: none;
    }
    &_text {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
  }
}

@media only screen
and (min-device-width : 360px)
and (max-device-width : 640px)
and (orientation : portrait) {
  .footer {
    margin-top: 25rem;
    &_left-icon {
      margin-top: -16rem !important;
      width: 22rem !important;
    }
    &_right-icon {
      display: none;
    }
    &_text {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
  }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {
  .footer {
    margin-top: 15rem;
    &_left-icon {
      margin-top: -16rem !important;
      width: 22rem !important;
    }
    &_right-icon {
      display: none;
    }
    &_text {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
  }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {
  .footer {
    margin-top: 10rem;
    &_left-icon {
      margin-top: -15rem !important;
      width: 20rem !important;
    }
    &_right-icon {
      display: none;
    }
    &_text {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
  }
}