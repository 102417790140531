.home-appear {
  opacity: 0;
}

.home-appear.home-appear-active {
  opacity: 1;
  transition: opacity .45s ease-in;
}

.work-appear {
  opacity: 0;
}

.work-appear.work-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.work_menu-appear {
  opacity: 0.75;
}

.work_menu-appear.work_menu-appear-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}

//.work_menu-enter {
//  opacity: 0;
//}
//.work_menu-enter.work_menu-enter-active {
//  opacity: 1;
//  transition: opacity 1s ease-in;
//}
//
//.work_menu-leave {
//  opacity: 1;
//}
//.work_menu-leave.work_menu-leave-active {
//  opacity: 0;
//  transition: opacity 1s ease-in;
//}



.photo-appear {
  opacity: 0;
}

.photo-appear.photo-appear-active {
  opacity: 1;
  transition: opacity 1.2s ease-in;
}