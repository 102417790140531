.header {
  height: 80vmin;
  width: 100%;

  &_bg {
    background: url("../../../assets/img/back_01.jpg") no-repeat left;
    background-size: cover;
  }

  &_info {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 1rem;
    margin: 0 0;
  }

  &_social {
    font-size: 1.9vh;
    font-family: Calibri, bold;
    color: #f1dfc2;
    text-align: right;
    &-icon {
      width: 42px;
    }
  }

  &_tel {
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 6.9vmin;
    font-family: Calibri, bold;
    color: #f1dfc2;
    text-align: center;
  }

  &_adr {
    font-family: Calibri, regular;
    color: #633b22;
    text-align: left;
  }
}
@media screen and (max-width: 1200px) {
  .header {
    &_social {
      font-size: 1.4rem;
      &-icon {
        margin-top: 1rem;
        width: 3rem;
      }
    }
    &_tel {
      font-size: 52px;
    }
  }
}

@media screen and (max-width: 810px) {
  .header {
    &_social {
      font-size: 1.4vh;
      &-icon {
        margin-top: 1rem;
        width: 2rem;
      }
    }
  }
}

@media screen and (max-width: 469px) {
  .header {
    &_social {
      font-size: 1.5vh;
      font-family: Calibri, bold;
      text-align: center;
      &-icon {
        width: 34px;
      }
    }
    &_tel {
      font-size: 6.5vmin;
      font-family: Calibri, bold;
    }
    &_adr {
      display: none;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : landscape) {
  .header {
    height: 100vmin;
    &_social {
      font-size: 0.8rem;
      &-icon {
        width: 1.7rem;
      }
    }
    &_tel {
      font-size: 1.8rem;
    }
    &_adr {
      display: none;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
  .header {
    height: 80vmin;
    &_social {
      font-size: 0.8rem;
      &-icon {
        width: 1.7rem;
      }
    }
    &_tel {
      font-size: 1.8rem;
    }
    &_adr {
      display: none;
    }
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {
  .header {
    &_social {
      font-size: 1.2vh;
      &-icon {
        width: 1.3rem;
      }
    }
    &_tel {
      font-size: 1.6rem;
    }
  }
}

@media only screen
and (min-device-width : 360px)
and (max-device-width : 640px)
and (orientation : portrait) {
  .header {
    &_social {
      font-size: 1.2vh;
      &-icon {
        width: 1.3rem;
      }
    }
    &_tel {
      font-size: 1.5rem;
    }
  }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {
  .header {
    &_social {
      margin-top: -0.5rem !important;
      font-size: 1.2vh;
      &-icon {
        width: 1rem;
      }
    }
    &_tel {
      font-size: 1.25rem;
    }
  }
}




